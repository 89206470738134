<template>
    <ContentWrapper>
        <breadcrumb/>

        <div class="container">
            <div class="row">
                <div class="col-12">

                  <div class="card card-default animated-fast fadeInUp" :class="loading ? 'whirl' : ''">
                    <div class="card-body">

                      <a @click.prevent="toggleSelect(type)" href="" class="badge badge-neutral badge-switch mb-1 mr-1" :class="{'selected': selectedTypes[type]}" v-for="type in types" :key="type">

                        <label class="switch d-inline mr-2">
                          <input type="checkbox" v-model="selectedTypes[type]"/>
                          <span></span>
                        </label>

                        <span>{{ $t('wallet.component.transactions.types.' + type + '.title') }}</span>
                      </a>

                      <div class="btn-toolbar justify-content-end">
                        <button class="btn btn-secondary btn-xs" @click="showInfo = !showInfo"><i class="fad fa-question mr-2"></i> Info</button>
                      </div>


                      <div v-show="showInfo">
                      <hr/>

                      <div v-for="type in types" :key="type + '_legend'">
                        <div class="badge badge-neutral mr-2">{{ $t('wallet.component.transactions.types.' + type + '.title') }}</div>
                        <span><i class="fad fa-long-arrow-right"></i> {{ $t('wallet.component.transactions.types.' + type + '.description') }}</span>
                      </div>

                      </div>

                      <hr/>

                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>{{ $t('wallet.view.summary.from.label') }}</label>
                            <input class="form-control" v-model="range.from"  type="date"/>
                            <small>{{ $t('wallet.view.summary.from.hint') }}</small>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>{{ $t('wallet.view.summary.to.label') }}</label>
                            <input class="form-control" v-model="range.to" type="date"/>
                            <small>{{ $t('wallet.view.summary.to.hint') }}</small>
                          </div>
                        </div>
                      </div>


                      <template v-if="selectedTypesArray.length">
                        <hr/>

                        <div class="text-center">
                          <button class="btn btn-primary mr-2" @click="submit()"><i class="fad fa-table mr-2"></i> Vorschau</button>
                          <button class="btn btn-primary" @click="exportFile()"><i class="fad fa-file-csv mr-2"></i> CSV Herunterladen</button>
                        </div>

                        <hr/>
                      </template>

                      <a href="" style="display: none;" ref="download"></a>

                      <div v-if="results">

                        <div class="text-center">
                          <div class="summary-panel mx-3 mb-2" v-for="(type, type_name) in results.sum" :key="type_name">
                            <p class="border-bottom">{{ $t('wallet.component.transactions.types.' + type_name + '.title') }}</p>
                            <p>{{ type.amount }} {{ type.currency }}</p>
                          </div>
                        </div>

                        <hr/>

                        <table class="table text-right" style="font-size: 0.7em">
                          <tr>
                            <td>Label</td>
                            <td>Typ</td>
                            <template v-for="currency in currencies">
                              <td :key="currency + 'pos'">{{ currency }} +</td>
                              <td :key="currency + 'neg'">{{ currency }} -</td>
                            </template>
                            <td>Date</td>
                          </tr>
                          <tr v-for="transaction in results.transactions" :key="transaction.label">
                            <td class="p-0">{{ transaction.label }}</td>
                            <td class="p-0 text-right">{{ $t('wallet.component.transactions.types.' + transaction.type + '.title') }}</td>
                            <template v-for="currency in currencies">
                              <td class="p-0 text-success" :key="currency + transaction.label + 'pos'">{{ currency === transaction.currency && transaction.positive ? transaction.amount + ' ' + transaction.currency : ''}}</td>
                              <td class="p-0 text-danger" :key="currency + transaction.label + 'neg'">{{ currency === transaction.currency && !transaction.positive ? transaction.amount + ' ' + transaction.currency : ''}}</td>
                            </template>
                            <td class="p-0">{{ $moment.utc(transaction.created_at).local().format('YYYY-MM-DD HH:mm')}}</td>
                          </tr>
                        </table>

                      </div>

                    </div>
                  </div>
                </div>
            </div>
        </div>
    </ContentWrapper>
</template>
<style lang="scss" scoped>

.summary-panel {
  display: inline-block;
  text-align: center;
}

</style>
<script>
    export default {
      components: {},
      data() {
            return {
              types: [],
              selectedTypes: {},
              selectedTypesArray: [],
              range: {
                from: '',
                to: '',
              },
              results: null,
              currencies: ['EUR', 'CBD'],
              loading: true,
              showInfo: false
            }
        },
        beforeMount() {
          this.loading = true;
          this.$api.get('wallet/summary/transaction-types').then(response => {
            this.types = response.data.types;
            this.range = response.data.range;
            this.loading = false;
          })
        },
        methods: {
          toggleSelect(type) {

            this.selectedTypes[type] = !this.selectedTypes[type];
            this.selectedTypesArray = [];

            Object.keys(this.selectedTypes).forEach(key => {
              if (this.selectedTypes[key]) {
                this.selectedTypesArray.push(key);
              }
            });
          },
          submit() {
            this.loading = true;
            this.$api.post('wallet/summary/transactions', {
              selected: this.selectedTypesArray,
              from: this.range.from,
              to: this.range.to
            }).then(response => {
                this.results = response.data;
                this.loading = false;
            })
          },
          exportFile() {
            this.loading = true;
            this.$api.post('wallet/summary/export', {
              selected: this.selectedTypesArray,
              from: this.range.from,
              to: this.range.to
            }).then(response => {
              let blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
              const url = window.URL.createObjectURL(blob);
              this.$refs.download.href = url;
              this.$refs.download.download = 'cannergrow-' + this.range.from + ' '  + this.range.to + '.csv';
              this.$refs.download.click();
              window.URL.revokeObjectURL(url);
              this.loading = false;
            })
          }
        }
    }
</script>
